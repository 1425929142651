import { FormattedMessage, useIntl } from 'react-intl'
import BookDivingSlider from './BookDivingSlider'
import { handleViewport } from 'react-in-viewport'

import { Buttons } from './Buttons'
import Loader from '../../helpers/Loader'
import { connect } from 'react-redux'
import { setTabType } from '../../../actions/dive-guide-actions'
import get from 'lodash/get'
import { target } from 'utils'
import useDiveItems from './hooks/useDiveItems'

import PropTypes from 'prop-types'

const BookDiving = ({
  tabs,
  location,
  selectedTab,
  setTabType,
  selectedCurrency,
  isWidget,
  isDiveSite,
  inViewport,
  enterCount,
  forwardedRef,
}) => {
  const intl = useIntl()
  const {
    isLoading,
    data,
    params,
    setParams,
    isShowPlaceholder,
  } = useDiveItems(
    isDiveSite,
    location,
    selectedCurrency,
    inViewport,
    enterCount,
  )

  const parentLocation = window.parentLocation

  const handleTabChange = (tabId) => {
    setTabType(tabId)
  }

  const getSearchUrl = (currentTab) => {
    let tab = tabs.find((tab) => tab.id === currentTab)
    return window?.parentLocation?.categoryUrls?.[tab?.route]
  }

  const getButtonMessage = (currentTab) => {
    const tab = tabs.find((tab) => tab.id === currentTab)
    if(!tab) {
      return {
        id: 'show_all',
        defaultMessage: 'Show all',
      }
    }
    return tab.buttonTitle
  }

  const resultsLength = get(data, 'results.length')

  if(!inViewport && enterCount === 0) {
    return <div ref={forwardedRef} />
  }

  const isNoResults = !resultsLength && (!isLoading || !isShowPlaceholder)

  return (
    <div className='slider-dg-wrapper max-width-1036' ref={forwardedRef}>
      <Buttons tabs={tabs} activeTab={selectedTab} onClick={handleTabChange} />
      <div className='slides-wrapper'>
        <Loader />
        {isNoResults ? (
          <div className='no-results'>
            <FormattedMessage
              id='book_diving.no_results'
              defaultMessage='No results'
            />
            <a href={getSearchUrl(data.tab, parentLocation.slug)}>
              <FormattedMessage
                id='book_diving.see_other'
                values={{
                  inTitle: parentLocation.title,
                }}
                defaultMessage='See other results { inTitle }'
              />
            </a>
          </div>
        ) : (
          <BookDivingSlider
            slidesAmount={4}
            tab={data.tab}
            data={data.results}
            total={data.total}
            getSearchUrl={getSearchUrl}
            getButtonMessage={getButtonMessage}
            setParams={setParams}
            params={params}
            isShowPlaceholder={isShowPlaceholder}
            isLoading={isLoading}
          />
        )}
        {!isWidget && (
          <div className='flex-row'>
            <a
              className='btn-guides-blue'
              target={target}
              href={getSearchUrl(data.tab)}
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='book-diving'
            >
              {intl.formatMessage(getButtonMessage(data.tab))}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToPros = (state) => ({
  selectedTab: state.selectedTab,
  selectedCurrency: state.selectedCurrency,
})

const BookDivingWithViewportHandler = handleViewport(
  BookDiving,
  {},
  { disconnectOnLeave: true },
)

export default connect(mapStateToPros, { setTabType })(
  BookDivingWithViewportHandler,
)

BookDiving.propTypes = {
  tabs: PropTypes.array,
  location: PropTypes.object,
  selectedTab: PropTypes.string,
  setTabType: PropTypes.func,
  selectedCurrency: PropTypes.string,
  isWidget: PropTypes.bool,
  isDiveSite: PropTypes.bool,
  inViewport: PropTypes.bool,
  enterCount: PropTypes.number,
  forwardedRef: PropTypes.object,
}
